import React from 'react'

// MUI Components
import Box from '@mui/material/Box'
import { Paper, ThemeProvider, createTheme } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import Typography from '@mui/material/Typography'

// Custom CSS
import css from './Footer.module.css'

let theme = createTheme({
    palette: {
        primary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        },
        secondary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        }
    },
})

const Footer = () => {
    return (
        <ThemeProvider theme={theme}>
            <Paper component={'footer'} elevation={16} sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 2
            }}>
                <Typography variant='p' component={'p'}>
                    ChoiceAlgo ® . Copyright {new Date().getUTCFullYear()}
                </Typography>
            </Paper>
        </ThemeProvider>
    )
}

export default Footer