import React from 'react'

// MUI Components
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { createTheme, ThemeProvider } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import PhotoCamera from '@mui/icons-material/PhotoCamera'

// assets
import css from './SignUp.module.css'
const hasNumber = /\d/

const theme = createTheme({
    palette: {
        primary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        },
        secondary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        }
    },
})

const StepOne = ({ registerUser, handleSelect, handleChange, handleNext }) => {

    return (
        <ThemeProvider theme={theme}>
            <React.Fragment>
                <legend>
                    <IconButton aria-label="upload picture" component="label" >
                        <img
                            width={150}
                            height={150}
                            src={registerUser.profile}
                            alt={registerUser.fullName}
                            loading="lazy"
                            style={{
                                maxWidth: 150,
                                maxHeight: 150,
                                borderRadius: 100,
                                position: 'absolute',
                                objectFit: 'cover',
                            }}
                        />
                        <PhotoCamera sx={{
                            zIndex: 9999,
                            opacity: 0,
                            ':hover': {
                                opacity: 1,
                            }
                        }} />
                        <input
                            hidden
                            accept="image/*"
                            type="file"
                            name='profile'
                            onChange={event => handleSelect(event)} />
                    </IconButton>
                </legend>
                <TextField
                    required
                    className={css.container_input}
                    name='fullName'
                    id="outlined-basic"
                    label="Full Name"
                    variant="outlined"
                    type={'text'}
                    value={registerUser.fullName}
                    error={(registerUser.fullName.length > 0 && registerUser.fullName.length <= 2) || hasNumber.test(registerUser.fullName)}
                    helperText={(registerUser.fullName.length > 0 && registerUser.fullName.length <= 2) || hasNumber.test(registerUser.fullName) ? 'Name Cannot be less than 3 and it must not contain any symbol or number!!' : ''}
                    onChange={event => handleChange(event)}
                />
                <TextField
                    required
                    className={css.container_input}
                    name='contactNo'
                    id="outlined-basic"
                    label="Contact No."
                    variant="outlined"
                    type={'tel'}
                    value={registerUser.contactNo}
                    error={registerUser.contactNo.length > 0 && registerUser.contactNo.length !== 10}
                    helperText={registerUser.contactNo.length > 0 && registerUser.contactNo.length !== 10 ? 'Contact Must contain 10 digits!!' : ''}
                    onChange={event => handleChange(event)}
                />
                <TextField
                    required
                    className={css.container_input}
                    name='email'
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    type={'text'}
                    value={registerUser.email}
                    error={registerUser.email.length > 1 && !registerUser.email.includes('@')}
                    helperText={registerUser.email.length > 1 && !registerUser.email.includes('@') ? 'Invalid Email!!' : ''}
                    onChange={event => handleChange(event)}
                />
                <TextField
                    required
                    className={css.container_input}
                    name='aadharNo'
                    id="outlined-basic"
                    label="Aadhar No."
                    variant="outlined"
                    type={'tel'}
                    value={registerUser.aadharNo}
                    error={registerUser.aadharNo.length < 12 && !Boolean(isNaN(parseInt(registerUser.aadharNo)))}
                    helperText={registerUser.aadharNo.length < 12 && !Boolean(isNaN(parseInt(registerUser.aadharNo))) ? 'Invalid Aadhaar Number!!' : ''}
                    onChange={event => handleChange(event)}
                />
                <TextField
                    required
                    className={css.container_input}
                    name='panNo'
                    id="outlined-basic"
                    label="Pan No."
                    variant="outlined"
                    type={'text'}
                    value={registerUser.panNo}
                    error={(registerUser.panNo.length > 0 && registerUser.panNo.length < 10) && !Boolean(registerUser.panNo.match(hasNumber))}
                    helperText={(registerUser.panNo.length > 0 && registerUser.panNo.length < 10) && !Boolean(registerUser.panNo.match(hasNumber)) ? 'Invalid Pan Card Number' : ''}
                    onChange={event => handleChange(event)}
                />
                <TextField
                    required
                    className={css.container_input}
                    name='accountNo'
                    id="outlined-basic"
                    label="Account No."
                    variant="outlined"
                    type={'tel'}
                    value={registerUser.accountNo}
                    error={registerUser.accountNo.length > 0 && (registerUser.accountNo.length < 9 || registerUser.accountNo.length > 18)}
                    helperText={registerUser.accountNo.length > 0 && (registerUser.accountNo.length < 9 || registerUser.accountNo.length > 18) ? 'Invalid Account Number' : ''}
                    onChange={event => handleChange(event)}
                />
                <TextField
                    required
                    className={css.container_input}
                    name='ifsc'
                    id="outlined-basic"
                    label="IFSC Code"
                    variant="outlined"
                    type={'text'}
                    value={registerUser.ifsc}
                    error={(registerUser.ifsc.length > 0 && registerUser.ifsc.length !== 11) && registerUser.ifsc.charAt(4) !== 0}
                    helperText={(registerUser.ifsc.length > 0 && registerUser.ifsc.length !== 11) && registerUser.ifsc.charAt(4) !== 0 ? 'Invalid IFSC!!' : ''}
                    onChange={event => handleChange(event)}
                />
                <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, justifyContent: 'space-between' }}>
                    <Button
                        color="inherit"
                        disabled={true}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Button
                        className={css.container_input}
                        type='button'
                        variant='contained'
                        color='primary'
                        sx={{ maxWidth: '100%' }}
                        onClick={handleNext}>Next</Button>
                </Box>
            </React.Fragment>
        </ThemeProvider>
    )
}

export default StepOne