import React, { memo, useState } from 'react'
import { Link } from 'react-router-dom'

// MUI components and icons
import Box from '@mui/material/Box'
import Menu from '@mui/material/Menu'
import Button from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import MenuItem from '@mui/material/MenuItem'
import { blueGrey } from '@mui/material/colors'
import Typography from '@mui/material/Typography'
import PersonIcon from '@mui/icons-material/Person'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';

// assets
import css from './Header.module.css'
import customCircle from '../../../../assets/blob.svg'

const drawerWidth = 240;
const navItems = [{
    id: 1,
    name: 'Home',
    link: '/'
},
{
    id: 2,
    name: 'About',
    link: '/about'
},
{
    id: 3,
    name: 'Subscription Plans',
    link: '/plans'
},
{
    id: 4,
    name: 'Feature',
    link: '/feature'
},
{
    id: 5,
    name: 'Contact Us',
    link: '/contact'
}];

const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Poppins",
        }
    },
    palette: {
        primary: {
            main: '#ff7676'
        },
        secondary: {
            main: '#6772e5'
        },
    }
})

const Header = (props) => {
    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{
            textAlign: 'center',
        }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                ChoiceAlgo
            </Typography>
            <Divider />
            <List sx={{ paddingTop: 0 }}>
                {/* Large Screens Menu */}
                {navItems.map((item) => (
                    <React.Fragment key={item.id}>
                        <Link to={item.link} className={css.text_decoration_none}>
                            <ListItem key={item} disablePadding>
                                <ListItemButton sx={{
                                    textAlign: 'center',
                                    color: '#212121',
                                    ':hover': { backgroundColor: theme.palette.secondary.main, color: blueGrey[50] }
                                }}>
                                    <ListItemText primary={item.name} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
        </Box>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar component="nav" sx={{
                    background: '#fff',
                    '&::before': {
                        content: "''",
                        display: 'block',
                        position: 'absolute',
                        width: '100%',
                        height: '451px',
                        background: `url(${customCircle})`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        top: -387,
                        left: {
                            lg: '-615px',
                            md: '-615px',
                            sm: '-430px',
                            xs: 0
                        },
                    },
                }}>
                    <Toolbar>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                display: { xs: 'block', sm: 'none' },
                                mr: 'auto',
                            }}
                        >
                            ChoiceAlgo
                        </Typography>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            ChoiceAlgo
                        </Typography>
                        <Box sx={{ display: { xs: 'none', sm: 'flex' }, flexGrow: 1, }}>
                            {/* Large Screens Menu */}
                            {navItems.map((item) => (
                                <Link key={item.id} to={item.link} className={css.text_decoration_none}>
                                    <Button sx={{
                                        color: '#212121',
                                        ':hover': { backgroundColor: '#fff', color: theme.palette.primary.main, border: `1px solid ${theme.palette.primary.main}` }
                                    }}>{item.name}</Button>
                                </Link>
                            ))}
                        </Box>
                        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                            {/* Large Screens Menu */}
                            {BasicMenu()}
                        </Box>
                    </Toolbar>
                </AppBar>
                <nav>
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, },
                        }}
                    >
                        {drawer}
                        <Divider style={{ marginTop: 'auto' }} />
                        <Link to={'/user/login'} className={css.text_decoration_none} >
                            <MenuItem sx={{
                                color: '#212121',
                                marginLeft: 2,
                                ':hover': { backgroundColor: theme.palette.secondary.main, color: blueGrey[50], textShadow: '0 0 4px #fff,0 0 6px #fff,0 0 10px #fff,0 0 4px #228dff,0 0 6px #228dff,0 0 10px #228dff' },
                                m: 0,
                                p: '.5rem 2rem',
                                transition: 'all',
                                transitionDuration: '300ms',
                                textShadow: 0
                            }}>Login</MenuItem>
                        </Link>
                        <Divider />
                        <Link to={'/user/signup'} className={css.text_decoration_none}>
                            <MenuItem sx={{
                                color: '#212121',
                                marginLeft: 2,
                                ':hover': { backgroundColor: theme.palette.secondary.main, color: blueGrey[50], textShadow: '0 0 4px #fff,0 0 6px #fff,0 0 10px #fff,0 0 4px #228dff,0 0 6px #228dff,0 0 10px #228dff' },
                                m: 0,
                                p: '.5rem 2rem',
                                transition: 'all',
                                transitionDuration: '300ms',
                                textShadow: 0
                            }}>Sign Up</MenuItem>
                        </Link>
                        <Divider />
                        <Typography color={'#455a64'} fontSize={12} padding={1}>All Rights Reserved &#169;{new Date().getUTCFullYear()}</Typography>
                    </Drawer>
                </nav>
            </Box>
        </ThemeProvider >
    )
}

export default memo(Header)

function BasicMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant='contained' sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: '#fff',
                        marginLeft: 'auto',
                        ':hover': { backgroundColor: '#fff', color: theme.palette.primary.main },
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: 1
                    }}
                    onClick={handleClick}
                >
                    <PersonIcon /> Account
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                    sx={{
                        '.MuiMenu-list': { p: 0, backgroundColor: '#fafafa' }
                    }}
                >
                    <Link to={'/user/login'} className={css.text_decoration_none}>
                        <MenuItem onClick={handleClose} sx={{
                            color: '#212121',
                            marginLeft: 2,
                            ':hover': { backgroundColor: blueGrey[900], color: blueGrey[50], textShadow: '0 0 4px #fff,0 0 6px #fff,0 0 10px #fff,0 0 4px #228dff,0 0 6px #228dff,0 0 10px #228dff' },
                            m: 0,
                            p: '.5rem 2rem',
                            transition: 'all',
                            transitionDuration: '300ms',
                            textShadow: 0
                        }}>Login</MenuItem>
                    </Link>
                    <Link to={'/user/signup'} className={css.text_decoration_none}>
                        <MenuItem onClick={handleClose} sx={{
                            color: '#212121',
                            marginLeft: 2,
                            ':hover': { backgroundColor: blueGrey[900], color: blueGrey[50], textShadow: '0 0 4px #fff,0 0 6px #fff,0 0 10px #fff,0 0 4px #228dff,0 0 6px #228dff,0 0 10px #228dff' },
                            m: 0,
                            p: '.5rem 2rem',
                            transition: 'all',
                            transitionDuration: '300ms',
                            textShadow: 0
                        }}>Sign Up</MenuItem>
                    </Link>
                </Menu>
            </Box>
        </ThemeProvider>
    );
}