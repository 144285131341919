import { LoadingButton } from '@mui/lab'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
const basicImg = require('../../../../assets/user_default.png')

const Logout = () => {
    const navigate = useNavigate()
    useEffect(() => {
        const user = JSON.stringify({
            fullName: '',
            contactNo: '',
            email: '',
            aadharNo: '',
            panNo: '',
            accountNo: '',
            ifsc: '',
            profile: basicImg,
            username: '',
            password: '',
            userID: null,
            authenticated: false
        })
        localStorage.setItem('user', user)

        navigate('/user/login', { replace: true })
    })

    return (
        <LoadingButton loading variant="outlined" sx={{ zIndex: 99999 }} />
    )
}

export default Logout