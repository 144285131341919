import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// MUI Components
import { Box } from '@mui/system'
import { blueGrey } from '@mui/material/colors'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { Button, Container, Fade, FormControl, InputAdornment, OutlinedInput, Paper, Slide, Snackbar, Tab, ThemeProvider, Typography, createTheme, responsiveFontSizes } from '@mui/material'

// Assets
import { bookTrade, fetchAccount, getTradeDetails } from '../../../Api/Axios'

let theme = createTheme({
    palette: {
        primary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        },
        secondary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        }
    },
})
theme = responsiveFontSizes(theme)
let nf = new Intl.NumberFormat('en-IN')

const Trade = () => {
    const [value, setValue] = useState('1')
    const [isLoading, setIsLoading] = useState(true)
    const [tradeData, setTradeData] = useState({})
    const [noti, setNoti] = useState({
        open: false,
        Transition: Fade,
    })
    const [premium, setPremium] = useState({
        shares: 0,
        stopLoss: 0,
        target: 0,
    })
    const [account, setAccount] = useState({ usedMargin: 0, balance: 0 })
    const [response, setResponse] = useState(null)
    const data = useLocation().state
    const user = JSON.parse(localStorage.user)
    const navigate = useNavigate()

    const SlideTransition = (props) => {
        return <Slide style={{ backgroundColor: blueGrey[50], color: '#000', fontWeight: 'bold' }} {...props} direction="up" />
    }

    const handleTabChange = (event, newValue) => setValue(newValue)

    const handleChange = (event) => {
        setPremium(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    const handleSubmit = async () => {
        setIsLoading(true)
        if (premium.shares !== 0) {
            await bookTrade(response, setResponse, premium, data, user.userID, parseInt(tradeData.price) * (parseInt(premium.shares) * parseInt(tradeData.size)), account)
            setNoti({
                open: true,
                Transition: SlideTransition,
            })
            setTimeout(() => navigate(`/orders/${user.userID}`, { state: data.id }), 3000)
        } else alert('Fields cannot be empty!')
        setTimeout(() => {
            setNoti({
                open: false,
                Transition: SlideTransition
            })
        }, 5000)
    }

    useEffect(() => {
        fetchAccount(account, setAccount, user.userID)
        getTradeDetails(tradeData, data.id, setTradeData)

        setTimeout(() => {
            getTradeDetails(tradeData, data.id, setTradeData)
        }, 10000)

        return setIsLoading(false)
    }, [])
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{
                marginTop: 8,
            }}>
                <Paper sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '0px 8px 8px 8px',
                }}>
                    <Typography textTransform={'uppercase'}>
                        {tradeData.name}
                        <Typography paddingLeft={1} variant='small' component={'small'}>
                            {tradeData.cepe ? tradeData.cepe : ''}
                        </Typography>
                    </Typography>
                    <Typography variant='span'>{`₹ ${nf.format(parseFloat(tradeData.price))}`}</Typography>
                </Paper>
                <Container sx={{
                    display: 'grid',
                    gridTemplateRows: '.1fr .1fr .85fr'
                }}>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1, 1fr)',
                        alignItems: 'baseline',
                        backgroundColor: blueGrey[50],
                        padding: 1,
                        borderRadius: 1,
                        marginTop: 2
                    }}>
                        <Box sx={{
                            display: 'grid',
                            gridTemplateColumns: 'auto auto'
                        }}>
                            <Typography textAlign={'start'} py={1} variant='small' component={'h5'}>Max Qty Available</Typography>
                            <Typography textAlign={'end'} py={1} variant='small' component={'h5'}>
                                {`${Math.floor(parseInt(account.balance) / (parseInt(tradeData.size) * parseInt(tradeData.price)))} Shares`}
                            </Typography>
                        </Box>
                        <FormControl variant="outlined">
                            <Typography textAlign={'center'} py={2} variant='small' component={'small'}>Enter Qty</Typography>
                            <OutlinedInput
                                id="outlined-adornment-shares"
                                sx={{
                                    textAlign: 'center',
                                    color: 'inherit'
                                }}
                                autoFocus={true}
                                autoComplete='off'
                                endAdornment={<InputAdornment position="end">{
                                    tradeData.market === 'Commodity' ? `Qty(${tradeData.size})` : tradeData.market === 'Cash' ? `Shares(${tradeData.size})` : `Lot(${tradeData.size})`
                                }</InputAdornment>}
                                inputProps={{
                                    'aria-label': 'Shares',
                                    title: 'Enter'
                                }}
                                name='shares'
                                required
                                type='number'
                                error={parseInt(account.balance) / (parseInt(tradeData.size) * parseInt(tradeData.price)) < premium.shares}
                                onChange={event => handleChange(event)}
                            />
                        </FormControl>
                    </Box>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: '.7fr 1fr',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        gap: '16px',
                        width: '100%',
                        '> div': {
                            paddingX: 0
                        }
                    }}>
                        <TabContext value={value}>
                            <TabPanel value="1">
                                <Box>
                                    <Typography variant='small' component={'small'} fontSize={'3vw'}>Stop Loss</Typography>
                                    <FormControl variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-stopLoss"
                                            inputProps={{
                                                'aria-label': 'stopLoss',
                                            }}
                                            defaultValue={premium.stopLoss}
                                            name='stopLoss'
                                            onChange={event => handleChange(event)}
                                        />
                                    </FormControl>
                                </Box>
                            </TabPanel>
                            <TabPanel value="2">
                                <Box>
                                    <Typography variant='small' component={'small'} fontSize={'3vw'}>Target</Typography>
                                    <FormControl variant="outlined">
                                        <OutlinedInput
                                            id="outlined-adornment-target"
                                            inputProps={{
                                                'aria-label': 'target',
                                            }}
                                            name='target'
                                            defaultValue={premium.target}
                                            onChange={event => handleChange(event)}
                                        />
                                    </FormControl>
                                </Box>
                            </TabPanel>
                            <TabList onChange={handleTabChange} aria-label="Hold Type" sx={{
                                height: '50px',
                                textAlign: 'right'
                            }} >
                                <Tab label="SL" value="1" wrapped />
                                <Tab label="Target" value="2" wrapped />
                            </TabList>
                        </TabContext>
                    </Box>
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridTemplateRows: '1fr',
                        alignItems: 'end'
                    }}>
                        <Typography variant='small' component={'small'} textAlign={'left'} fontSize={'3vw'}>{`Margin Required(Approx)`}</Typography>
                        <Typography variant='small' component={'small'} textAlign={'right'} fontSize={'3vw'}>{`Availabel Cash`}</Typography>
                        <Typography variant='small' component={'small'} textAlign={'left'} fontSize={'3vw'}>
                            {
                                premium.shares > 0 ? (
                                    `₹ ${nf.format(parseInt(tradeData.price) * (parseInt(premium.shares) * parseInt(tradeData.size)))}`
                                ) : `₹ 0`
                            }
                            {
                                parseInt(account.balance) < parseInt(tradeData.price) * (parseInt(premium.shares) * parseInt(tradeData.size)) ? <Typography variant='small' component={'small'} color={'red'} fontSize={'3vw'}>{`(Insufficient Funds)`}</Typography> : ''
                            }
                        </Typography>
                        <Typography variant='small' color={account.balance < 0 ? 'red' : 'blueGrey'} component={'small'} textAlign={'right'} fontSize={'3vw'}>
                            {`₹ ${nf.format(parseFloat(account.balance))}`}
                        </Typography>
                        {
                            isLoading ? (
                                <LoadingButton loading sx={{ marginRight: 1, marginTop: 1 }} variant="outlined">BUY</LoadingButton>
                            ) : (
                                <>
                                    <Button disabled={parseInt(account.balance) < parseInt(tradeData.price) * (parseInt(premium.shares) * parseInt(tradeData.size))} variant="contained" sx={{ marginRight: 1, marginTop: 1 }}
                                        onClick={handleSubmit}>BUY</Button>
                                </>
                            )
                        }
                        <Button variant="contained" disabled sx={{ marginLeft: 1, marginTop: 1 }}>SELL</Button>
                        {
                            response ? (
                                <Snackbar
                                    open={noti.open}
                                    TransitionComponent={noti.Transition}
                                    message={'Ordered Succesfully'}
                                    sx={{
                                        backgroundColor: blueGrey[50],
                                        color: 'black !important'
                                    }}
                                    key={noti.Transition.name}
                                />
                            ) : (
                                <Snackbar
                                    open={noti.open}
                                    TransitionComponent={noti.Transition}
                                    message={'Something went wrong please try again!!'}
                                    sx={{
                                        backgroundColor: blueGrey[50],
                                        color: 'black !important'
                                    }}
                                    key={noti.Transition.name}
                                />
                            )
                        }
                    </Box>
                </Container>
            </Box>
        </ThemeProvider>
    )
}

export default Trade