import React from 'react'

// MUI Components
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { blueGrey } from '@mui/material/colors'
import Typography from '@mui/material/Typography'
import { createTheme, Divider, ThemeProvider } from '@mui/material'

// assets
import css from './About.module.css'
import CommonCard from '../home/Card'
import { Vision } from '../../Api/PageContent'
import multiWave from '../../../assets/multi-layer-wave.svg'

// components
import BreadCrumbNavigation from '../common/breadcrumb/BreadCrumbNavigation'

const theme = createTheme({
    palette: {
        primary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        },
        secondary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        }
    },
})

const About = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box>
                {/* Hero Banner Start */}
                <Paper sx={{
                    width: '100%',
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: `url(${multiWave}) no-repeat`,
                    backgroundSize: 'cover',
                }}>
                    <Typography
                        variant='h2'
                        className={css.heading} mt={7}>About Us</Typography>
                    <BreadCrumbNavigation pageName={'About Us'} />
                    <Grid container paddingX={{
                        md: 20,
                        xs: 1
                    }}>
                        <Grid sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            paddingBottom: {
                                lg: 5,
                                md: 5,
                                sm: 0,
                                xs: 0,
                            },
                        }}>
                            <p className={css.text_align_justify}>
                                There are trillions of rupees of wealth in India, lying in unproductive assets, losing value over time. We will help millions of Indians do better with their wealth
                            </p>
                        </Grid>
                    </Grid>
                </Paper>
                {/* Hero Banner End */}

                <Box sx={{
                    paddingBottom: 10,
                }}>
                    <Typography
                        variant='h2'
                        className={css.heading} sx={{
                            position: 'relative',
                            '&::before': {
                                content: "''",
                                position: 'absolute',
                                bottom: 5,
                                left: 0,
                                right: 0,
                                mx: 'auto',
                                width: 300,
                                borderBottom: '2px solid #6772e5',
                            }
                        }}>OUR Story</Typography>
                    <Grid container item xs={12} paddingX={{
                        md: 20,
                        sm: 1,
                        xs: 1,
                    }}>
                        <Grid item md={7} sm={11} xs={11} mx={'auto'} my={7}>
                            <Typography variant='p' component={'p'} textAlign={'justify'}>
                                {`We expanded our proposition from India to Bharat, with 70% of our new users coming from Tier 2 / 3 markets. This exponential growth came because of reducing barriers to entry to all customers. In 2021, we took this rocketship to the moon with our partnership with the Indian Premier League (IPL). In 2020, we had crossed a cumulative number of a million customers, and in 2021, we added the same number in a single month! Our first nationwide partnership with the world’s largest cricketing league along with our mass media campaign made us a household brand across the country.We are just getting started, and we will continue to build for every Indian’s prosperity.`}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Typography
                        variant='h2'
                        className={css.heading} sx={{
                            position: 'relative',
                            mt: 4,
                            '&::before': {
                                content: "''",
                                position: 'absolute',
                                bottom: 5,
                                left: 0,
                                right: 0,
                                mx: 'auto',
                                width: 300,
                                borderBottom: '2px solid #6772e5',
                            }
                        }}>Our Vision</Typography>
                    <Grid container paddingX={{
                        md: 20,
                        xs: 1
                    }}
                        mt={5}
                        columns={12}>
                        <CommonCard Slicing={false} PageContent={Vision} />
                    </Grid>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

export default About