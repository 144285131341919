export const Services = [
    {
        id: 1,
        Name: 'intraday',
        Img: require('../../assets/intraday.png'),
        Details: `Intraday Trading requires frequent buying and selling of stocks within the same day. ChoiceAlgo provides Best Intraday Trading Strategies for aggressive traders. Our Intraday Trading services are designed to help you make the most of the market movements throughout the day.`,
        MediumScreen: 3,
        SmallScreen: 12,
        Shadow: 16,
        link: `/services/${'intraday'}`
    },
    {
        id: 2,
        Name: 'short-term',
        Img: require('../../assets/short-term.png'),
        Details: `Best short term investment plans involves strategies to buy stock expected to go up in the short term because of momentum. Choose from a variety of services for your short term investments need.`,
        MediumScreen: 3,
        SmallScreen: 12,
        Shadow: 16,
        link: `/services/${'short-term'}`
    },
    {
        id: 3,
        Name: 'medium-term',
        Img: require('../../assets/medium-term.png'),
        Details: `ChoiceAlgo Investment Advisors - Award winning SEBI registered investment advisors providing Best Investment Plans In Share Market for Medium Term in India. ChoiceAlgo has a wide range of products which are designed to suit the investment needs of everyone.`,
        MediumScreen: 3,
        SmallScreen: 12,
        Shadow: 16,
        link: `/services/${'medium-term'}`
    },
    {
        id: 4,
        Name: 'long-term',
        Img: require('../../assets/long-term.png'),
        Details: `Retirement planning or higher education for your new born child, whatever your long term goal is, we have a tailor made service for your needs. Plan your long term investments with ChoiceAlgo`,
        MediumScreen: 3,
        SmallScreen: 12,
        Shadow: 16,
        link: `/services/${'long-term'}`
    }]

export const Features = [
    {
        id: 5,
        Name: 'Risk Management in Stock Market',
        Img: require('../../assets/risk-management-800.png'),
        Details: `Having a trading plan helps traders to easily trade in the markets as the parameters have been pre-set.`,
        Date: 'April 9, 2022',
        MediumScreen: 4,
        SmallScreen: 12,
        Shadow: 16,
        link: '/contact'
    },
    {
        id: 6,
        Name: 'Trading Plan',
        Img: require('../../assets/trading-plan-800.png'),
        Details: `Having a trading plan helps traders to easily trade in the markets as the parameters have been pre-set.`,
        Date: 'May 10, 2022',
        MediumScreen: 4,
        SmallScreen: 12,
        Shadow: 16,
        link: '/contact'
    },
    {
        id: 7,
        Name: 'The Power of Compounding',
        Img: require('../../assets/compounding-800.png'),
        Details: `"Compound Interest is considered to be the eighth wonder of the world.`,
        Date: 'May 14, 2022',
        MediumScreen: 4,
        SmallScreen: 12,
        Shadow: 16,
        link: '/contact'
    }]

export const Achivements = [
    {
        id: 8,
        Img: require('../../assets/trusted.png'),
        Name: 'Trusted Brand',
        Details: '15+Years',
        MediumScreen: 4,
        SmallScreen: 12,
        Shadow: 0,
    },
    {
        id: 9,
        Img: require('../../assets/experience.png'),
        Name: 'Total Experience',
        Details: '3,00,000+Hours of research',
        MediumScreen: 4,
        SmallScreen: 12,
        Shadow: 0,
    },
    {
        id: 10,
        Img: require('../../assets/customer.png'),
        Name: 'Customers',
        Details: '80,000+Customers Served',
        MediumScreen: 4,
        SmallScreen: 12,
        Shadow: 0,
    },
]

export const Vision = [
    {
        id: 11,
        Img: require('../../assets/objective.png'),
        Name: 'Objective',
        Details: 'The objective is to  continue deliver value by ensuring customer’s success. This will be achieved by adhering to the highest standards of business conduct',
        MediumScreen: 4,
        SmallScreen: 12,
        Shadow: 8,
    },
    {
        id: 12,
        Img: require('../../assets/mission.png'),
        Name: 'Mission',
        Details: 'We view our mission in reliable-efficient responsible delivery of financial research to our consumers and help them create wealth out of their savings.',
        MediumScreen: 4,
        SmallScreen: 12,
        Shadow: 8,
    },
    {
        id: 13,
        Img: require('../../assets/values.png'),
        Name: 'Values',
        Details: 'Respect every Individual, Bring Passion to Work, Be Disciplined, Committed & Transparent Demand Excellence from Self & Others What’s Right than Who’s Right Have Fun@ Work',
        MediumScreen: 4,
        SmallScreen: 12,
        Shadow: 8,
    },
]

export const Subscriptions = [
    {
        Name: 'index option',
        price: '₹20k - ₹25k',
    },
    {
        Name: 'stock option',
        price: '₹25k',
    },
    {
        Name: 'index future',
        price: '₹80k',
    },
    {
        Name: 'silver',
        price: '₹50k',
    },
    {
        Name: 'gold',
        price: '₹60k',
    },
    {
        Name: 'crude',
        price: '₹40k',
    },
]

export const customerReviews = [
    {
        Name: 'Hariom Choudhary',
        review: 'What a awesome services & assistance of option research I just want to keep taking services continue from year to year.',
        rating: 5,
    },
    {
        Name: 'vijay kumar',
        review: 'Due to lack of knowledge & fear of option market I never traded in option bt when I have taken service from option research .I felt it is not quite tough to trade in option & now I am doing proper and safe trade.',
        rating: 4.5,
    },
    {
        Name: 'Amit hinduja',
        review: 'I loved the way as like the executive of option research treat us.they provide proper mobile support by which I can be able to fetch every moment of market.',
        rating: 5,
    },
    {
        Name: 'rajat sharma',
        review: "I was continuosly suffering from loss & abt to stop my trade bt wen I jst came to know option research thn I have taken recovery LOSSESS package and I recovered complete lossess now I'm making per month around 15 lakhs.",
        rating: 4,
    },
]

export const heroSlide = [
    {
        title: 'Algo trading For everyone.',
        desc: "ChoiceAlgo has been created to empower strategy creators. How? By allowing them to automate their quant strategies and sell them to investors and traders the world over. The best part? You never have to write a single bit of code or download clunky algo trading software's."
    },
    {
        title: 'Algo Trading made easy.',
        desc: "More than 100 keywords available to set your various conditions. Hundreds of automated trading strategies to duplicate for free and then amend as per your use case. Free Paper Trading. Limited use Free for Life making ChoiceAlgo the best automated trading software in india."
    },
    {
        title: 'Algo Trading in the cloud.',
        desc: "No software to download . No linking of data. No extra charges for data. No Coding. Dedicated bots assigned to your strategy for ultra-fast continuous condition checking and execution. "
    },
]