import React from 'react'
import { Link } from 'react-router-dom';

// MUI Components
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { blueGrey } from '@mui/material/colors';
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { ThemeProvider, createTheme } from '@mui/material/styles'

// assets
const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Poppins",
        }
    },
    palette: {
        primary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        },
        secondary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        }
    },
})

const CommonCard = (props) => props.PageContent.map((card) => {
    const name = card.Name.split('-').reverse()

    return (
        <Grid
            key={card.id}
            item
            lg={props.responsiveMD ? props.responsiveMD : card.MediumScreen}
            md={props.responsiveMD ? props.responsiveMD : card.MediumScreen}
            sm={card.SmallScreen}
            xs={card.SmallScreen}>
            <ThemeProvider theme={theme}>
                <Card elevation={card.Shadow} sx={{
                    margin: 2,
                    borderRadius: 3,
                }}>
                    <CardMedia
                        component="img"
                        image={card.Img}
                        alt={card.Name}
                        sx={{
                            maxWidth: '100%',
                            height: "auto",
                            objectFit: 'contain',
                            borderRadius: 1,
                        }}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div" sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            textTransform: 'capitalize',
                        }}>
                            {
                                name.length === 2 ? `${name.pop()} ${name.pop()}` : `${name.pop()}`
                            }
                        </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{
                                marginBottom: 2,
                                textAlign: 'justify',
                            }}>
                            {(card.Details.length > 50) && (props.Slice) ? `${card.Details.slice(0, 50)}...` : card.Details}
                        </Typography>
                        {
                            (card.link !== '/contact') ? (<CardActions>
                                <Link to={card.link} style={{ textDecoration: 'none', width: '100%' }}>
                                    <Button size="small" variant='contained' fullWidth>Read More</Button>
                                </Link>
                            </CardActions>) : (<Typography>{card.Date}</Typography>)
                        }

                    </CardContent>
                </Card>
            </ThemeProvider>
        </Grid>
    )
})

export default CommonCard