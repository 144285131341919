import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'

// MUI Components
import Box from '@mui/system/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import MuiAlert from '@mui/material/Alert'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/system/Container'
import TextField from '@mui/material/TextField'
import { blueGrey } from '@mui/material/colors'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import { Snackbar, ThemeProvider, createTheme } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

// assets
import { login } from '../Auth'
import css from './Login.module.css'
const theme = createTheme({
    palette: {
        primary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        },
        secondary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        }
    },
})

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const Login = () => {
    const [isClicked, setIsClicked] = useState(false)
    const [open, setOpen] = useState(false)
    const [err, setErr] = useState(false)
    const [user, setUser] = useState({
        username: '',
        password: '',
    })
    const navigate = useNavigate()

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    const handleErrClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setErr(false)
    }

    const handleChange = async (e) => {
        setUser(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const handleLogin = async (e) => {
        e.preventDefault()
        setIsClicked(true)
        if (user.username !== '' && user.password !== '') {
            const response = await login(user)
            if (response.response) {
                setOpen(true)
                setTimeout(() => {
                    localStorage.setItem('user', JSON.stringify(response.response))
                    navigate(`/dashboard/${response.id}`)
                }, 2000)
            } else {
                if (response.message) {
                    alert(response.message)
                }
                setErr(true)
                setIsClicked(false)
            }
        } else {
            setErr(true)
            setIsClicked(false)
        }

    }

    return (
        <ThemeProvider theme={theme}>
            <SnackbarProvider>
                <Box sx={{ flexGrow: 1, height: '95.3vh', }}>
                    {/* Head Start */}
                    <AppBar position="static">
                        <Toolbar>
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{ mr: 2 }}
                                onClick={() => {
                                    navigate('/user/signup')
                                }}
                            >
                                <KeyboardBackspaceIcon />
                            </IconButton>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    marginInline: 'auto',
                                    textAlign: 'center',
                                }}
                            >
                                Welcome to <Link to={'/'} style={{ textDecoration: 'none', color: 'inherit' }}>ChoiceAlgo</Link>
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {/* Head End */}

                    {/* Form Body Start */}
                    <Container
                        sx={{
                            marginTop: 15,
                            paddingBottom: 4,
                        }}
                    >
                        <Paper
                            elevation={16}
                        >
                            <Container>
                                <Box sx={{
                                    width: '100%',
                                    height: '100%',
                                    py: 3,
                                }}
                                    component={'form'}
                                    className={css.container}
                                    onSubmit={handleLogin}
                                >
                                    <Typography component={'small'} textAlign={'center'} pb={2} >
                                        Happy to see you again!!
                                    </Typography>
                                    <TextField
                                        required
                                        name='username'
                                        id="username"
                                        label="Username"
                                        variant="outlined"
                                        type={'text'}
                                        value={user.username}
                                        onChange={event => handleChange(event)}
                                        sx={{
                                            marginBottom: 2,
                                        }}
                                    />
                                    <TextField
                                        required
                                        name='password'
                                        id="password"
                                        label="Password"
                                        variant="outlined"
                                        type={'password'}
                                        value={user.password}
                                        onChange={event => handleChange(event)}
                                        sx={{
                                            marginBottom: 1,
                                        }}
                                    />
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}>
                                        {
                                            isClicked ?
                                                <LoadingButton loading variant="outlined">
                                                    Submit
                                                </LoadingButton> :
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    type='submit'
                                                    sx={{
                                                        ':hover': { backgroundColor: blueGrey[900], color: blueGrey[50] },
                                                    }}
                                                >Login</Button>
                                        }
                                        <p style={{ fontSize: '12px', textAlign: 'end' }}>Don't have an account? <Link to={'/user/signup'} style={{
                                            textDecoration: 'none',
                                            paddingLeft: 8,
                                            ':hover': { backgroundColor: blueGrey[900], color: blueGrey[50] },
                                        }}>SignUp!!</Link></p>
                                    </Box>
                                </Box>
                            </Container>
                        </Paper>
                    </Container>
                    {/* Form Body End */}

                </Box>
                {/* Footer Start */}
                <Paper
                    elevation={24}
                    component={'footer'}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingX: 3,
                        backgroundColor: blueGrey[50],
                        borderRadius: 0,
                    }}
                >
                    <Grid>
                        <Typography
                            component={'p'}
                            sx={{
                                fontSize: {
                                    lg: 'large',
                                    md: 'large',
                                    sm: '12px',
                                    xs: '12px',
                                },
                                padding: 1,
                            }}
                        >
                            Copyright © {new Date().getUTCFullYear()} ChoiceAlgo Limited
                        </Typography>
                    </Grid>
                </Paper>
                {/* Footer End */}

                <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{
                        width: '100%',
                        backgroundColor: blueGrey[50],
                        color: '#000',
                        fontWeight: 'bold'
                    }} >
                        Login Successfull
                    </Alert>
                </Snackbar>
                <Snackbar open={err} autoHideDuration={3000} onClose={handleErrClose}>
                    <Alert onClose={handleErrClose} severity="info" sx={{
                        width: '100%',
                        backgroundColor: blueGrey[50],
                        color: '#000',
                        fontWeight: 'bold'
                    }} >
                        User Not Found!!
                    </Alert>
                </Snackbar>
            </SnackbarProvider>
        </ThemeProvider>
    )
}
export default Login