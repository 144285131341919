import { createContext, useContext, useEffect, useState } from "react"

export const ModeContext = createContext()

export const useModeContext = () => {
    return useContext(ModeContext)
}

export const ModeContextProvider = ({ children }) => {
    const [mode, setMode] = useState(JSON.parse(localStorage.getItem('mode') || null))

    useEffect(() => {
        localStorage.setItem('mode', JSON.stringify(mode))
    }, [mode])

    return <ModeContext.Provider value={{ mode, setMode }}>
        {children}
    </ModeContext.Provider>
}