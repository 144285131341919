import React from 'react'
import { Link, useLocation } from 'react-router-dom'

// MUI Components
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { createTheme, ThemeProvider } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import Typography from '@mui/material/Typography'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

// assets
import css from './Service.module.css'
import CommonCard from '../home/Card'
import { Services } from '../../Api/PageContent'

const theme = createTheme({
    palette: {
        primary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        },
        secondary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        }
    },
})

const Service = () => {
    const query = useLocation().pathname.split('/')
    const notToRenderItem = query.toString().split(',').pop()
    const content = Services.find(item => {
        return item.Name === notToRenderItem
    })
    const title = notToRenderItem.split('-').reverse()

    return (
        <ThemeProvider theme={theme}>
            <Box>
                <Paper
                    elevation={16}
                    sx={{
                        backgroundColor: blueGrey[50],
                        paddingTop: 2,
                        paddingBottom: 5,
                    }}
                >
                    <Grid container paddingX={{
                        md: 20,
                        xs: 1
                    }}>
                        <Link to={'/'} className={css.back_button}>
                            <Button variant="contained" startIcon={<ArrowBackIcon />}>
                                Back
                            </Button>
                        </Link>
                        <Typography
                            variant='h2'
                            className={css.heading}
                            paddingBottom={{
                                sm: 0,
                                xs: 0,
                            }}
                            fontSize={{
                                sm: '30px',
                                xs: '30px',
                            }}
                        >
                            {
                                title.length === 2 ? `${title.pop()} ${title.pop()}` : `${title.pop()}`
                            }
                        </Typography>
                        <Grid sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            borderTop: 'thin solid black',
                            marginTop: 2,
                        }}>
                            <img src={content.Img} alt={content.Name} width='100%' />
                            <p className={css.text_align_justify}>{content.Details}</p>
                        </Grid>
                    </Grid>
                </Paper>

                {/* Other Services Start */}
                <Box sx={{
                    paddingTop: 5,
                    paddingBottom: 10,
                }}>
                    <Typography
                        variant='h2'
                        className={css.heading}>Other Services</Typography>
                    <Grid container paddingX={{
                        md: 20,
                        xs: 1,
                    }}>
                        <CommonCard responsiveMD={4} Slice={true} PageContent={
                            Services.filter((item) => {
                                return item.Name !== notToRenderItem
                            })
                        } />
                    </Grid>
                </Box>
                {/* Other Services End */}

                {/* Footer for Service Only */}
                <Paper
                    elevation={24}
                    component={'footer'}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingX: 3,
                        backgroundColor: blueGrey[50],
                        borderRadius: 0,
                    }}
                >
                    <Grid>
                        <Typography
                            component={'p'}
                            sx={{
                                fontSize: {
                                    lg: 'large',
                                    md: 'large',
                                    sm: '12px',
                                    xs: '12px',
                                },
                                padding: 1,
                            }}
                        >
                            Copyright © {new Date().getUTCFullYear()} ChoiceAlgo Research Limited
                        </Typography>
                    </Grid>
                </Paper>
            </Box>
        </ThemeProvider>
    )
}

export default Service