import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

// MUI Components
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { blueGrey } from '@mui/material/colors'
import Container from '@mui/system/Container'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

// assets
import StepOne from './StepOne'
import { signup } from '../Auth'
import FinalStep from './FinalStep'
import css from './SignUp.module.css'
import Resizer from "react-image-file-resizer"
import basicImg from '../../../../assets/user_default.png'

const SignUp = () => {
    const navigate = useNavigate()
    const [activeStep, setActiveStep] = useState(0)
    const [isClicked, setIsClicked] = useState(false)
    const [registerUser, setRegisterUser] = useState({
        fullName: '',
        contactNo: '',
        email: '',
        aadharNo: '',
        panNo: '',
        accountNo: '',
        ifsc: '',
        profile: basicImg,
        username: '',
        password: '',
    })

    const resizeFile = (file) => new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            600,
            600,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64"
        )
    })

    const handleSelect = async (event) => {
        if (event.target.files && event.target.files[0]) {
            const image = await resizeFile(event.target.files[0])
            setRegisterUser(prev => ({ ...prev, profile: image }))
            setRegisterUser(prev => ({ ...prev, profileToSend: event.target.files[0] }))
        }
    }

    const handleChange = (event) => {
        setRegisterUser(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    const handleSubmit = async () => {
        setIsClicked(true)
        let response = await signup(registerUser)
        let user = JSON.parse(localStorage.user)
        if (response.id === user.userID) {
            alert(`Registered Successfully!! Your registration ID: ${response.id}`)
            navigate('/user/login')
            setIsClicked(false)
        } else {
            alert('Something went wrong!')
            setIsClicked(false)
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handlePrev = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    const getFormData = (activeStep) => {
        switch (activeStep) {
            case 0: return <StepOne
                registerUser={registerUser}
                handleChange={handleChange}
                handleSelect={handleSelect}
                handleNext={handleNext}
            />
            case 1: return <FinalStep
                registerUser={registerUser}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handlePrev={handlePrev}
                isClicked={isClicked}
            />
            default:
                return <StepOne
                    registerUser={registerUser}
                    handleChange={handleChange}
                    handleSelect={handleSelect}
                    handleNext={handleNext}
                />
        }
    }

    return (
        <Box sx={{ flexGrow: 1, }}>
            {/* Head Start */}
            <AppBar position="static" sx={{ backgroundColor: blueGrey[50] }}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color='#212121'
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => {
                            navigate(-1)
                        }}
                    >
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{
                            marginInline: 'auto',
                            textAlign: 'center',
                            color: '#212121',
                        }}
                    >
                        Welcome to <Link to={'/'} style={{ textDecoration: 'none', color: '#212121' }}>ChoiceAlgo</Link>
                    </Typography>
                    <Box>
                        <Link to={'/user/login'} style={{ textDecoration: 'none' }}>
                            <Button variant='contained' sx={{
                                backgroundColor: '#fafafa',
                                color: '#212121',
                                marginLeft: 'auto',
                                ':hover': { backgroundColor: blueGrey[900], color: blueGrey[50] }
                            }}>Login</Button>
                        </Link>
                    </Box>
                </Toolbar>
            </AppBar>
            {/* Head End */}

            {/* Form Body Start */}
            <Container
                sx={{
                    marginTop: 15,
                    paddingBottom: 4,
                    minHeight: {
                        sm: '100vh',
                        xs: '100%'
                    },
                }}
            >
                <Paper
                    elevation={16}
                >
                    <Box sx={{ width: '100%' }}>
                        <Container>
                            <form
                                className={css.container}
                            >
                                {getFormData(activeStep)}
                            </form>
                        </Container>
                    </Box>
                </Paper>
            </Container>
            {/* Form Body End */}

            {/* Footer Start */}
            <Paper
                elevation={24}
                component={'footer'}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingX: 3,
                    backgroundColor: blueGrey[50],
                    borderRadius: 0,
                }}
            >
                <Grid>
                    <Typography
                        component={'p'}
                        sx={{
                            fontSize: {
                                lg: 'large',
                                md: 'large',
                                sm: '12px',
                                xs: '12px',
                            },
                            padding: 1,
                        }}
                    >
                        Copyright © {new Date().getUTCFullYear()} ChoiceAlgo Limited
                    </Typography>
                </Grid>
            </Paper>
            {/* Footer End */}
        </Box>
    )
}

export default SignUp