import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { RouterProvider } from 'react-router-dom';
import { ModeContextProvider } from './components/panel/contexts/ModeContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ModeContextProvider>
    <RouterProvider router={App} />
  </ModeContextProvider>
);
