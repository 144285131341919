import { CardContent, CardHeader, Skeleton, useTheme } from '@mui/material'
import React from 'react'

const LoadingSkele = ({ styles }) => {
    const theme = useTheme()

    return (
        <>
            <CardHeader
                sx={{
                    marginTop: styles.marginTop,
                    background: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                }}
                avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
                action={null}
                title={
                    <Skeleton
                        animation="wave"
                        height={10}
                        width="80%"
                        style={{ marginBottom: 6 }}
                    />
                }
                subheader={<Skeleton animation="wave" height={10} width="40%" />}
            />
            <Skeleton sx={{
                height: {
                    sm: '50vh',
                    xs: '40vh'
                }
            }} animation="wave" variant="rectangular" />

            <CardContent>
                <React.Fragment>
                    <Skeleton animation="wave" height={12} style={{ marginBottom: 10 }} />
                    <Skeleton animation="wave" height={12} width="80%" />
                    <br />
                    <Skeleton animation="wave" height={12} style={{ marginBottom: 10 }} />
                    <Skeleton animation="wave" height={12} width="80%" />
                    <br />
                    <Skeleton animation="wave" height={12} style={{ marginBottom: 10 }} />
                    <Skeleton animation="wave" height={12} width="80%" />
                </React.Fragment>
            </CardContent>
        </>
    )
}

export default LoadingSkele