import React from 'react'

// MUi
import Typography from '@mui/material/Typography';
import HomeIcon from '@mui/icons-material/Home';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import GrainIcon from '@mui/icons-material/Grain';

const BreadCrumbNavigation = ({ pageName }) => {
    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb">
                <Link
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/"
                >
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    ChoiceAlgo
                </Link>
                <Typography
                    sx={{ color: 'text.primary', display: 'flex', alignItems: 'center' }}
                >
                    <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                    {pageName}
                </Typography>
            </Breadcrumbs>
        </Box>
    )
}

export default BreadCrumbNavigation