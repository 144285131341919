import axios from "axios"
import Resizer from "react-image-file-resizer"

const baseUrl = process.env.REACT_APP_url
const axiosConfig = {
    headers: {
        'Content-Type': 'application/json charset=UTF-8'
    }
}

export const submitRequest = async (guest) => {
    const body = {
        name: guest.firstName,
        lastname: guest.lastName,
        email: guest.email,
        mobile: guest.mobileNumber,
        question: guest.yourQuestion
    }

    let response = await axios.post(`${baseUrl}/contact`, body, axiosConfig).then(response => response.data).catch(err => console.warn(err))

    return response
}

export const signup = async (data) => {
    const body = {
        name: data.fullName,
        email: data.email,
        contact: data.contactNo,
        password: data.password,
        username: data.username.toLowerCase(),
        aadhar: data.aadharNo,
        pan: data.panNo,
        account: data.accountNo,
        ifsc: data.ifsc,
        profile: data.profileToSend,
        authenticated: false,
    }

    const axiosConfig = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }

    let response = await axios.post(`${baseUrl}/signup`, body, axiosConfig).then(response => response.data).catch(err => err)
    const user = {
        name: response.name,
        email: response.email,
        contact: response.contact,
        password: response.password,
        username: response.username,
        aadhar: response.addhar,
        pan: response.pan,
        account: response.account,
        ifsc: response.ifsc,
        profile: data.profile,
        authenticated: false,
        userID: response.id
    }

    localStorage.setItem('user', JSON.stringify(user))
    return response
}

export const login = async (data) => {
    if (data.username !== '' && data.password !== '') {
        const body = {
            username: data.username.toLowerCase(),
            password: data.password
        }

        let response = await axios.post(`${baseUrl}/login`, body, axiosConfig).then(response => response.data).catch(err => console.warn(err))

        if (response.length > 0) {
            if (response[0].activation) {
                const resizeFile = (file) => new Promise((resolve) => {
                    Resizer.imageFileResizer(
                        file,
                        600,
                        600,
                        "JPEG",
                        100,
                        0,
                        (uri) => {
                            resolve(uri);
                        },
                        "base64"
                    )
                })

                let image = await axios.get(`${process.env.REACT_APP_user_profile}/${response[0].profile}`, { responseType: 'blob' }).then(response => response.data).catch(err => console.warn(err.message))

                if (image) {
                    const file = await resizeFile(image)

                    const user = {
                        fullName: response[0].name,
                        contactNo: response[0].contact,
                        email: response[0].email,
                        aadharNo: response[0].addhar,
                        panNo: response[0].pan,
                        accountNo: response[0].account,
                        ifsc: response[0].ifsc,
                        profile: file,
                        username: response[0].username,
                        password: response[0].password,
                        userID: response[0].id,
                        balance: response[0].amount,
                        authenticated: true,
                        usedMargin: response[0].used_margin,
                    }
                    return { id: user.userID, response: user }
                } else {
                    const user = {
                        fullName: response[0].name,
                        contactNo: response[0].contact,
                        email: response[0].email,
                        aadharNo: response[0].addhar,
                        panNo: response[0].pan,
                        accountNo: response[0].account,
                        ifsc: response[0].ifsc,
                        profile: null,
                        username: response[0].username,
                        password: response[0].password,
                        userID: response[0].id,
                        balance: response[0].amount,
                        authenticated: true,
                        usedMargin: response[0].used_margin,
                    }
                    return { id: user.userID, response: user }
                }
            } else {
                return { message: 'Account Activation under process. Please try again later!!' }
            }

        } else {
            return false
        }
    } else return false
}