import React from 'react'
import { LoadingButton } from '@mui/lab'

//Mui components
import { Box } from '@mui/system'
import { blueGrey } from '@mui/material/colors'
import { Button, Card, CardContent, Container, Grid, Paper, TextField, Typography } from '@mui/material'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CallIcon from '@mui/icons-material/Call'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import EmailIcon from '@mui/icons-material/Email'
import LocationOnIcon from '@mui/icons-material/LocationOn'

// assets
import defaultImg from '../../../assets/contact.jpg'
import css from './Contact.module.css'
import { useState } from 'react'
import { submitRequest } from '../auth/Auth'
const theme = createTheme({
    typography: {
        allVariants: {
            fontFamily: "Poppins",
        }
    },
    palette: {
        primary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        },
        secondary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        }
    },
})

const Contact = () => {
    const [guest, setGuest] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mobileNumber: '',
        yourQuestion: ''
    })
    const [isClicked, setIsClicked] = useState(false)

    const handleChange = (event) => {
        setGuest(prev => ({ ...prev, [event.target.name]: event.target.value }))
    }

    const handleRequest = async () => {
        let response = await submitRequest(guest)

        console.log(response)
    }

    return (
        <ThemeProvider theme={theme}>
            <Box>
                {/* -----Hero Banner Start----- */}
                <Paper elevation={24} sx={{
                    width: 'auto',
                    height: {
                        md: '500px',
                        xs: '480px'
                    },
                    backgroundImage: `url(${defaultImg})`,
                    backgroundSize: 'cover',
                    backgroundPosition: {
                        md: 'top',
                        xs: 'inherit'
                    },
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    textAlign: {
                        md: 'right',
                        xs: 'center'
                    },
                }}>
                    <Typography component={'div'} sx={{
                        display: {
                            md: 'none',
                            xs: 'block'
                        },
                        position: 'absolute',
                        top: '56px',
                        left: '0px',
                        right: '0px',
                        bottom: '0px',
                        width: 'auto',
                        height: {
                            md: '532px',
                            xs: '512px'
                        },
                        opacity: '0.518',
                        backgroundColor: '#000',
                        borderRadius: '4px',
                    }} />
                    <Grid sx={{
                        mixBlendMode: 'hard-light',
                        '& div, span, h4, p': {
                            fontSize: {
                                md: '3rem',
                                xs: '2rem'
                            },
                            letterSpacing: 3,
                        },
                        marginLeft: 'auto',
                        marginRight: {
                            md: 0,
                            xs: 'auto'
                        }
                    }}>
                        <Typography
                            variant='h4'
                            sx={{
                                lineHeight: '48px',
                                fontWeight: 700,
                                color: {
                                    md: '#000',
                                    xs: '#fff'
                                },
                                mixBlendMode: 'hard-light',
                            }}>We’re here! <br /> Let’s have a talk</Typography>
                    </Grid>
                </Paper>
                {/* -----Hero Banner End----- */}

                {/* -----Contact Section Start */}
                <Container sx={{
                    paddingBottom: 10,
                    marginTop: {
                        md: 10,
                        xs: -13
                    },
                    paddingLeft: {
                        md: 2,
                        xs: 1
                    },
                    paddingRight: {
                        md: 2,
                        xs: 1
                    },
                    position: {
                        md: 'inherit',
                        xs: 'absolute'
                    }
                }}>
                    <Card elevation={16} sx={{
                        borderRadius: 3,
                        display: {
                            md: 'grid',
                            xs: 'block'
                        },
                        gridTemplateColumns: '1.5fr 2fr'
                    }}>
                        <div>
                            <Typography
                                variant='h2'
                                sx={{
                                    backgroundColor: blueGrey[900],
                                    color: blueGrey[50],
                                }}
                                className={css.heading}>Contact Information</Typography>
                            <CardContent sx={{
                                backgroundColor: blueGrey[900],
                                '> *': {
                                    color: blueGrey[50],
                                    fontSize: {
                                        xs: '.8rem !important'
                                    },
                                    display: 'flex',
                                    alignItems: 'center'
                                },
                                display: 'grid',
                                gridTemplateColumns: {
                                    md: '.6fr 2fr',
                                    xs: '1fr 2fr'
                                },
                                gridGap: {
                                    md: '4rem 0rem',
                                    xs: '.5rem'
                                },
                                alignItems: 'start',
                                justifyContent: 'space-between'
                            }}>
                                <Typography gutterBottom variant="h5" component="div" sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textTransform: 'capitalize',
                                }}>
                                    <CallIcon sx={{ marginRight: '.5rem !important', }} />Phone
                                </Typography>
                                <Typography gutterBottom variant="h5" component="div" sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textTransform: 'capitalize',
                                }}>+91-76-9741-4408
                                </Typography>

                                <Typography gutterBottom variant="h5" component="div" sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textTransform: 'capitalize',
                                }}>
                                    <AccessTimeFilledIcon sx={{ marginRight: '.5rem !important', }} />Open
                                </Typography>
                                <Typography gutterBottom variant="h5" component="div" sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textTransform: 'capitalize',
                                }}>Between 9:30 AM to 6:30 PM Monday to Saturday
                                </Typography>

                                <Typography gutterBottom variant="h5" component="div" sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textTransform: 'capitalize',
                                }}>
                                    <EmailIcon sx={{ marginRight: '.5rem !important', }} />Email
                                </Typography>
                                <Typography gutterBottom variant="h5" component="div" sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                }}>info@choicealgo.in
                                </Typography>

                                <Typography gutterBottom variant="h5" component="div" sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textTransform: 'capitalize',
                                }}>
                                    <LocationOnIcon sx={{ marginRight: '.5rem !important', }} />Office
                                </Typography>
                                <Typography gutterBottom variant="h5" component="div" sx={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textTransform: 'capitalize',
                                }}>36 b,1ST FLOOR, near Hotel G shy, Anna Nagar, Zone-II, Maharana Pratap Nagar, Bhopal, Pradesh 462011
                                </Typography>
                            </CardContent>
                        </div>

                        <div>
                            <Typography
                                variant='h2'
                                className={css.heading}>Write to Us</Typography>
                            <CardContent sx={{
                                paddingTop: 0,
                                '> *': {
                                    fontSize: {
                                        xs: '.8rem !important'
                                    },
                                    display: 'flex',
                                    alignItems: 'center'
                                }
                            }}>
                                <Container>
                                    <Box sx={{
                                        width: '100%',
                                        height: '100%',
                                        py: 3,
                                        display: {
                                            md: 'grid',
                                            xs: 'block'
                                        },
                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                        gridGap: '.5rem'
                                    }}
                                        component={'form'}
                                    >
                                        <TextField
                                            required
                                            name='firstName'
                                            id="firstName"
                                            label="First Name"
                                            variant="outlined"
                                            type={'text'}
                                            onChange={event => handleChange(event)}
                                            sx={{
                                                marginBottom: 2,
                                            }}
                                            fullWidth
                                        />
                                        <TextField
                                            required
                                            name='lastName'
                                            id="lastName"
                                            label="Last Name"
                                            variant="outlined"
                                            type={'text'}
                                            onChange={event => handleChange(event)}
                                            sx={{
                                                marginBottom: 1,
                                            }}
                                            fullWidth
                                        />
                                        <TextField
                                            required
                                            name='email'
                                            id="email"
                                            label="Email"
                                            variant="outlined"
                                            type={'email'}
                                            onChange={event => handleChange(event)}
                                            sx={{
                                                marginBottom: 2,
                                            }}
                                            fullWidth
                                        />
                                        <TextField
                                            required
                                            name='mobileNumber'
                                            id="mobileNumber"
                                            label="Mobile Number"
                                            variant="outlined"
                                            type={'number'}
                                            onChange={event => handleChange(event)}
                                            sx={{
                                                marginBottom: 2,
                                            }}
                                            fullWidth
                                        />
                                        <TextField
                                            required
                                            name='yourQuestion'
                                            id="yourQuestion"
                                            label="Your Question"
                                            variant="outlined"
                                            type={'text'}
                                            placeholder='Enter Your Query Here!'
                                            onChange={event => handleChange(event)}
                                            sx={{
                                                marginBottom: 2,
                                                gridColumn: {
                                                    md: '1/3',
                                                }
                                            }}
                                            fullWidth
                                        />
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end',
                                            gridColumn: {
                                                md: '1/3',
                                            },
                                        }}>
                                            {
                                                isClicked ?
                                                    <LoadingButton loading variant="outlined">
                                                        Send
                                                    </LoadingButton> :
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={handleRequest}
                                                        sx={{
                                                            ':hover': { backgroundColor: blueGrey[900], color: blueGrey[50] },
                                                        }}
                                                    >Send</Button>
                                            }
                                        </Box>
                                    </Box>
                                </Container>
                            </CardContent>
                        </div>
                    </Card>
                </Container>
                {/* -----Contact Section End */}
            </Box>
        </ThemeProvider>
    )
}

export default Contact