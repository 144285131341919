import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

// MUI Components
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { blueGrey } from '@mui/material/colors'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { useTheme } from '@emotion/react'
import { Divider, ThemeProvider } from '@mui/material'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CardActions from '@mui/material/CardActions';
import Rating from '@mui/material/Rating';
import SavingsIcon from '@mui/icons-material/Savings';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';

// swiper js
import { Swiper, SwiperSlide } from 'swiper/react';

// swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'

// swiper modules
import { FreeMode, Autoplay, Navigation } from 'swiper/modules'

// assets
import CommonCard from './Card'
import css from './Home.module.css'
import LoadingSkele from '../../ui-skeleton/LoadingSkele'
import { Services, Features, Achivements, Subscriptions, customerReviews, heroSlide } from '../../Api/PageContent'
import heroVideo from '../../../assets/hero-vid.mp4'
import wave from '../../../assets/wave.svg'
import multiWave from '../../../assets/multi-layer-wave.svg'
import customCircle from '../../../assets/blob.svg'
import reviewNote from '../../../assets/review-note.jpg'
import aboutBanner from '../../../assets/about-new-banner.jpg'

const list = [
    {
        price: 0,
        title: 'Commissions',
        desc: 'On investing in Mutual Funds and IPOs',
        icon: <SavingsIcon />,
    },
    {
        price: 0,
        title: 'Account Maintanance',
        desc: 'To maintain Your Demat Account',
        icon: <ManageAccountsIcon />,
    },
    {
        price: 0,
        title: 'Brokerage',
        desc: 'On Equity, F&O, Commodity and currency orders',
        icon: <CurrencyRupeeIcon />,
    },
]

const Home = () => {
    const [isLoading, setLoading] = useState(true)
    const theme = useTheme()

    useEffect(() => {
        setTimeout(() => setLoading(false), 2000)
    }, [])

    return (
        isLoading ?
            <LoadingSkele styles={{
                marginTop: 9
            }} /> : (
                <ThemeProvider theme={theme}>
                    <Box>
                        {/* -----Hero Banner Start----- */}
                        <Grid container spacing={1} mt={7} item xs={12}>
                            <Grid item md={6} sm={12} xs={12} sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                background: `url(${wave}) no-repeat`,
                                padding: 0,
                                backgroundPosition: 'bottom',
                            }}>
                                <Swiper
                                    slidesPerView={1}
                                    navigation={true}
                                    freeMode={true}
                                    loop={true}
                                    autoplay={{
                                        delay: 3500,
                                        disableOnInteraction: false,
                                    }}
                                    modules={[FreeMode, Autoplay, Navigation]}
                                    className={css.marginX}
                                >
                                    {
                                        heroSlide.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <Typography marginLeft={{
                                                    sm: 3,
                                                    xs: 2,
                                                }} variant='h3' component={'h3'}>{item.title}</Typography>
                                                <Typography marginX={{
                                                    sm: 3,
                                                    xs: 2,
                                                }} variant='p' component={'p'} mt={3} textAlign={'justify'}>
                                                    {item.desc}
                                                </Typography>
                                            </SwiperSlide>
                                        ))
                                    }
                                    <Link to={'/contact'}>
                                        <Button sx={{
                                            width: 150,
                                            marginLeft: {
                                                sm: 3,
                                                xs: 2,
                                            },
                                            mt: 2
                                        }} variant="outlined">Contact Us</Button></Link>
                                </Swiper>
                            </Grid>
                            <Grid md={6} display={{ md: 'flex', sm: 'none', xs: 'none' }}>
                                <video width={'100%'} height={'100%'} style={{ objectFit: 'cover' }} autoPlay muted loop>
                                    <source src={heroVideo} type="video/mp4" />
                                    <source src={heroVideo} type="video/ogg" />
                                    Your browser does not support the video tag.
                                </video>
                            </Grid>
                        </Grid>
                        {/* -----Hero Banner End----- */}

                        {/* -----About Section Start */}
                        <Box sx={{
                            backgroundColor: blueGrey[50],
                            paddingBottom: 10,
                            paddingTop: 5,
                            background: `url(${multiWave}) no-repeat`,
                            backgroundSize: 'cover',
                            px: 2,
                        }}>
                            <Card sx={{
                                position: 'relative',
                                maxWidth: 700,
                                mx: 'auto',
                                mb: 5,
                                borderRadius: 5,
                            }}>
                                <CardContent>
                                    <Typography
                                        variant='h5' component={'p'} textAlign={'center'} sx={{
                                            position: 'relative',
                                            '&::before': {
                                                content: "''",
                                                position: 'absolute',
                                                bottom: 0,
                                                left: 0,
                                                right: 0,
                                                mx: 'auto',
                                                width: 150,
                                                borderBottom: '2px solid #6772e5',
                                            }
                                        }}>Our Mission</Typography>
                                    <Typography component={'h5'} variant='h5' paddingY={2}>
                                        We are here to welcome you
                                    </Typography>
                                    <Typography variant='body1' component={'p'} pb={2} fontWeight={'light'} textAlign={'justify'}>
                                        We aim to provide fast and reliable platforms that seamlessly connect with your chosen resources, empowering every aspiring trader with the knowledge and tools of algorithms and automation.
                                    </Typography>
                                    <Typography variant='body1' component={'p'} textAlign={'justify'}>
                                        As a new generation participates in financial markets, automation is at the forefront, offering a future that minimizes the challenges of manual methods. With thousands of technical indicators and real-time market dynamics to explore, there’s a growing demand for a more powerful educational experience in technical analysis, which can be fully automated.”
                                    </Typography>
                                </CardContent>
                            </Card>
                            <Grid container item xs={12} gap={2} justifyContent={'center'} alignItems={'center'}>
                                {
                                    Services.map((item, index) => (
                                        <Grid key={index} item sm={2.5} xs={12} alignSelf={'stretch'} position={'relative'}>
                                            <img style={{
                                                width: '100%',
                                                height: '100',
                                                objectFit: 'contain'
                                            }} src={item.Img} alt={item.Name} />
                                            <Typography component={'h5'} variant='h5' sx={{
                                                position: 'relative',
                                                textTransform: 'capitalize',
                                                '&::before': {
                                                    content: "''",
                                                    position: 'absolute',
                                                    bottom: -3,
                                                    left: 0,
                                                    width: 150,
                                                    borderBottom: '2px solid #6772e5',
                                                },
                                                marginBottom: 2,
                                            }}>
                                                {item.Name}
                                            </Typography>
                                            <Typography component={'p'} variant='p' mb={5}>
                                                {item.Details}
                                            </Typography>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                        {/* -----About Section End */}

                        {/* Achivement Section Start */}
                        <Divider />
                        <Box sx={{
                            paddingBottom: 10,
                        }}>
                            <Typography
                                variant='h2'
                                className={css.heading}>You can depend on us!!</Typography>
                            <Grid container paddingX={{
                                md: 20,
                                xs: 1
                            }}>
                                {
                                    Achivements.map((card) => {
                                        return (
                                            <Grid
                                                key={card.id}
                                                item
                                                lg={card.MediumScreen}
                                                md={card.MediumScreen}
                                                sm={card.SmallScreen}
                                                xs={card.SmallScreen}
                                                textAlign={'center'}>
                                                <Card elevation={0} sx={{
                                                    margin: 2,
                                                    borderRadius: 3,
                                                }}>
                                                    <CardMedia
                                                        component="img"
                                                        image={card.Img}
                                                        alt={card.Name}
                                                        sx={{
                                                            width: 50,
                                                            objectFit: 'contain',
                                                            borderRadius: 1,
                                                            marginInline: 'auto',
                                                            color: blueGrey[50]
                                                        }}
                                                    />
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="div" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                            {card.Name}
                                                        </Typography>
                                                        <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                                                            {card.Details}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                        <Divider />
                        {/* Achivement Section End */}

                        {/* Subscription Section Start */}
                        <Box sx={{
                            paddingBottom: 10,
                        }}>
                            <Typography
                                variant='h2'
                                className={css.heading} sx={{
                                    position: 'relative',
                                    mb: 7,
                                    '&::before': {
                                        content: "''",
                                        position: 'absolute',
                                        bottom: 5,
                                        left: 0,
                                        right: 0,
                                        mx: 'auto',
                                        width: 300,
                                        borderBottom: '2px solid #6772e5',
                                    }
                                }}>Subscription Plans</Typography>
                            <Grid container item columns={12} gap={{ md: 1, xs: 3 }} justifyContent={'center'} alignItems={'center'} textAlign={'center'} position={'relative'}>
                                {
                                    Subscriptions.map((item, index) => (
                                        <Grid item sm={3} xs={12} key={index} mx={{ md: 0, xs: 5 }} sx={{
                                            position: 'relative',
                                            cursor: 'default'
                                        }}>
                                            <Card sx={{
                                                position: 'relative',
                                                transition: '200ms',
                                                overflow: 'hidden',
                                                '&::before': {
                                                    content: "''",
                                                    display: 'block',
                                                    position: 'absolute',
                                                    width: '100%',
                                                    height: '300px',
                                                    background: `url(${customCircle})`,
                                                    backgroundSize: 'contain',
                                                    backgroundRepeat: 'no-repeat',
                                                    top: -120,
                                                    right: -195,
                                                    transform: 'rotate(20deg)',
                                                },
                                                ':hover': {
                                                    boxShadow: '0px 0px 10px 5px rgba(255,118,118,1)',
                                                    overflow: 'hidden',
                                                }
                                            }}>
                                                <CardContent>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        p: 1,
                                                        mx: 'auto',
                                                        mb: 2,
                                                        color: '#fff',
                                                        background: theme.palette.secondary.main,
                                                        borderRadius: 100,
                                                        width: 'fit-content'
                                                    }}>
                                                        <CurrencyRupeeIcon />
                                                    </Box>
                                                    <Divider />
                                                    <Typography variant='h5' component={'h5'} textTransform={'capitalize'} py={1}>{item.Name}</Typography>
                                                    <Typography variant='h4' component={'p'} pb={1}>
                                                        {item.price}
                                                    </Typography>
                                                    <Divider />
                                                    <Typography variant='poster' component={'p'} textTransform={'capitalize'} pt={1}>
                                                        Capital Req.
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Box>
                        {/* Subscription Section End */}

                        {/* Featured Section Start */}
                        <Box sx={{
                            paddingBottom: 10,
                            background: `linear-gradient( rgba(225, 225, 225, 0.3), rgba(225, 225, 225, 0.3) ), url(${aboutBanner}) no-repeat`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center top',
                            backgroundAttachment: 'fixed',
                        }}>
                            <Typography
                                variant='h2'
                                className={css.heading} sx={{
                                    position: 'relative',
                                    mb: 4,
                                    '&::before': {
                                        content: "''",
                                        position: 'absolute',
                                        bottom: 5,
                                        left: 0,
                                        right: 0,
                                        mx: 'auto',
                                        width: 270,
                                        borderBottom: '2px solid #6772e5',
                                    }
                                }}>Featured Blog</Typography>
                            <Grid container paddingX={{
                                md: 20,
                                xs: 1
                            }}
                                columns={12}>
                                <CommonCard Slice={true} PageContent={Features} />
                            </Grid>
                        </Box>
                        {/* Featured Section End */}

                        {/* Review Section Start */}
                        <Box sx={{
                            paddingBottom: 10,
                            textAlign: 'center',
                        }}>
                            <Typography
                                variant='h4'
                                component={'h3'}
                                className={css.heading} sx={{
                                    position: 'relative',
                                    '&::before': {
                                        content: "''",
                                        position: 'absolute',
                                        bottom: 15,
                                        left: 0,
                                        right: 0,
                                        mx: 'auto',
                                        width: 300,
                                        borderBottom: '2px solid #6772e5',
                                    }
                                }}>Our Customers</Typography>
                            <Typography component={'h4'} variant='subtitle2' sx={{
                                pb: 1,
                            }}>
                                Hear from ChoiceAlgo members.
                            </Typography>
                            <Typography variant='subtitle2' component={'p'} sx={{
                                pb: 5
                            }}>
                                Real Stories of what it means to <span style={{ color: theme.palette.primary.main }}>#Automate<span style={{ color: theme.palette.secondary.main }}>Money</span></span>
                            </Typography>
                            <Box gap={1} justifyContent={'center'} alignItems={'center'} textAlign={'center'} position={'relative'}>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={1}
                                    breakpoints={{
                                        // when window width is >= 480px
                                        500: {
                                            slidesPerView: 2,
                                            spaceBetween: 30
                                        },
                                        // when window width is >= 640px
                                        800: {
                                            slidesPerView: 3,
                                            spaceBetween: 30
                                        }
                                    }}
                                    navigation={true}
                                    freeMode={true}
                                    loop={true}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    modules={[FreeMode, Autoplay, Navigation]}
                                >
                                    {
                                        customerReviews.map((item, index) => (
                                            <SwiperSlide key={index} sx={{ position: 'relative', }}>
                                                <Box sx={{
                                                    position: 'relative',
                                                    transition: '200ms',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    maxWidth: {
                                                        sm: 400,
                                                        xs: 400
                                                    },
                                                    height: {
                                                        lg: 350,
                                                        md: 350,
                                                        xs: 380
                                                    },
                                                    border: 'none',
                                                    borderRadius: 0,
                                                    backgroundImage: `url(${reviewNote})`,
                                                    backgroundSize: 'cover',
                                                    cursor: 'default',
                                                }}>
                                                    <Box sx={{
                                                        width: {
                                                            lg: '60%',
                                                            md: '55%',
                                                            sm: '65%',
                                                            xs: '50%'
                                                        },
                                                        ml: {
                                                            lg: 0,
                                                            md: 5,
                                                            sm: 0,
                                                            xs: 0,
                                                        },
                                                        transform: 'rotate(3deg) skewY(1deg)',
                                                        color: '#575757',
                                                        fontSize: {
                                                            sm: '1.5vw',
                                                            xs: 10
                                                        },
                                                    }}>
                                                        <Box sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            mx: 'auto',
                                                            mt: {
                                                                lg: 7,
                                                                md: 2,
                                                                sm: 4,
                                                                xs: 2
                                                            },
                                                            mb: 1,
                                                        }}>
                                                            <Rating name="rating" defaultValue={item.rating} precision={0.5} readOnly />
                                                        </Box>
                                                        <Divider />
                                                        <Typography variant='p' component={'p'} py={2} fontSize={{
                                                            lg: 10,
                                                            md: 10,
                                                            sm: 12,
                                                            xs: 10
                                                        }}>
                                                            {item.review}
                                                        </Typography>
                                                        <Typography variant='h5' component={'h5'} textTransform={'capitalize'} pt={1} textAlign={'end'} fontSize={{
                                                            xs: 20
                                                        }}>{item.Name}</Typography>
                                                    </Box>
                                                </Box>
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            </Box>
                        </Box>
                        {/* Review Section End */}

                        {/* About Section Start */}
                        <Paper
                            sx={{
                                backgroundColor: blueGrey[50],
                                paddingBottom: 10,
                            }}
                        >
                            <Typography
                                variant='h2'
                                className={css.heading} sx={{
                                    position: 'relative',
                                    '&::before': {
                                        content: "''",
                                        position: 'absolute',
                                        bottom: 5,
                                        left: 0,
                                        right: 0,
                                        mx: 'auto',
                                        width: {
                                            sm: 400,
                                            xs: 340
                                        },
                                        borderBottom: '2px solid #6772e5',
                                    }
                                }}>Flat Fees, no hidden charges.</Typography>
                            <Grid container paddingX={{
                                md: 20,
                                xs: 1
                            }}>
                                <Grid item xs={12} justifyContent={'center'} alignItems={'center'}>
                                    <Timeline position="alternate">
                                        {
                                            list.map((item, index) => (
                                                <TimelineItem key={index}>
                                                    <TimelineOppositeContent
                                                        sx={{ m: 'auto 0' }}
                                                        variant="h4"
                                                        color={'#3e9c35'}
                                                    >
                                                        ₹ {item.price}
                                                    </TimelineOppositeContent>
                                                    <TimelineSeparator>
                                                        <TimelineConnector sx={{
                                                            background: theme.palette.secondary.main
                                                        }} />
                                                        <TimelineDot sx={{
                                                            background: theme.palette.primary.main
                                                        }}>
                                                            {item.icon}
                                                        </TimelineDot>
                                                        <TimelineConnector sx={{
                                                            background: theme.palette.secondary.main
                                                        }} />
                                                    </TimelineSeparator>
                                                    <TimelineContent sx={{ py: '12px', px: 2 }}>
                                                        <Typography variant="h6" component="span">
                                                            {item.title}
                                                        </Typography>
                                                        <Typography>{item.desc}</Typography>
                                                    </TimelineContent>
                                                </TimelineItem>
                                            ))
                                        }
                                    </Timeline>
                                </Grid>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column-reverse',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 2,
                                    mx: 'auto',
                                    borderTop: '1px solid lightgrey',
                                    width: '100%',
                                }}>
                                    <Link to={'/user/signup'} className={css.text_decoration_none}>
                                        <Button fullWidth variant='contained' sx={{
                                            color: '#fff',
                                            ':hover': {
                                                backgroundColor: '#fff',
                                                color: theme.palette.secondary.main
                                            }
                                        }}>START INVESTING</Button>
                                    </Link>
                                    <Typography variant='p' component={'p'} mt={4}>
                                        *Zero AMC is applicable for users onboarded from 2024 - 2025
                                    </Typography>
                                </Box>
                            </Grid>
                        </Paper>
                        {/* About Section End */}
                    </Box >
                </ThemeProvider >
            )
    )
}

export default Home