import React, { useEffect } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import { blueGrey } from '@mui/material/colors'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
]

const AppLogout = ({ children }) => {
    const [open, setOpen] = React.useState(false)
    let timer

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    const handleLogoutTimer = () => {
        timer = setTimeout(() => {
            resetTimer()

            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetTimer)
            })

            logoutAction()
        }, 120000)
    }

    const resetTimer = () => {
        if (timer) clearTimeout(timer)
    }

    useEffect(() => {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                resetTimer()
                handleLogoutTimer()
            })
        })
    }, [])

    const logoutAction = () => {
        // localStorage.clear()
        // setOpen(true)
        // if (!open) {
        //     setTimeout(() => window.location.pathname = "/user/login", 5000)
        // }
    }

    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="info" sx={{
                    width: '100%',
                    backgroundColor: blueGrey[50],
                    color: '#000',
                    fontWeight: 'bold'
                }} >
                    Logged out because of inactivity!
                </Alert>
            </Snackbar>
            {children}
        </>
    )
}

export default AppLogout