import React from 'react'

// Mui components
import { useTheme } from '@emotion/react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { ThemeProvider } from '@mui/material'
import Typography from '@mui/material/Typography'
import BreadCrumbNavigation from '../common/breadcrumb/BreadCrumbNavigation'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'

// swiper js
import { Swiper, SwiperSlide } from 'swiper/react';

// swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'

// swiper modules
import { FreeMode, Autoplay, Navigation } from 'swiper/modules'

// assets
import { heroSlide, Subscriptions } from '../../Api/PageContent'
import customCircle from '../../../assets/blob.svg'
import wave from '../../../assets/wave.svg'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import multiWave from '../../../assets/multi-layer-wave.svg'

const Features = () => {
    const theme = useTheme()

    return (
        <ThemeProvider theme={theme}>
            <Grid container item xs={12}>
                {/* Hero Banner Start */}
                <Paper sx={{
                    width: '100%',
                    padding: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: `url(${multiWave}) no-repeat`,
                    backgroundSize: 'cover',
                }}>
                    <Typography
                        variant='h2' component={'h2'} mt={7} sx={{
                            pt: 5,
                            pb: 2,
                            fontFamily: 'Poppins, sans-sarif',
                            fontSize: 38,
                            fontWeight: 500,
                            textAlign: 'center'
                        }}>Features</Typography>
                    <BreadCrumbNavigation pageName={'Features'} />
                    <Grid container paddingX={{
                        md: 20,
                        xs: 1
                    }}>
                        <Grid sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            paddingBottom: {
                                lg: 5,
                                md: 5,
                                sm: 0,
                                xs: 0,
                            },
                        }}>
                        </Grid>
                    </Grid>
                </Paper>
                {/* Hero Banner End */}

                {/* Features Start */}
                <Grid container xs={12} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: `url(${wave}) no-repeat`,
                    backgroundPosition: 'bottom',
                    pb: {
                        sm: 20,
                        xs: 6
                    }
                }}>
                    <Grid item xs={12}>
                        <Swiper
                            slidesPerView={1}
                            navigation={true}
                            freeMode={true}
                            loop={true}
                            autoplay={{
                                delay: 3500,
                                disableOnInteraction: false,
                            }}
                            modules={[FreeMode, Autoplay, Navigation]}
                            // className={css.marginX}
                            style={{
                                marginTop: 25,
                            }}
                        >
                            {
                                heroSlide.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <Typography marginLeft={{
                                            sm: 3,
                                            xs: 2,
                                        }} variant='h3' component={'h3'}>{item.title}</Typography>
                                        <Typography marginX={{
                                            sm: 3,
                                            xs: 2,
                                        }} variant='p' component={'p'} mt={3} textAlign={'justify'}>
                                            {item.desc}
                                        </Typography>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </Grid>
                    <Grid item xs={12}>
                        <Button sx={{
                            width: 150,
                            marginLeft: {
                                sm: 3,
                                xs: 2,
                            },
                            mt: 2
                        }} variant="outlined">Contact Us</Button>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{
                    paddingBottom: 10,
                }}>
                    <Typography component={'p'} variant='p' textAlign={'justify'} my={7} mx={{ sm: 10, xs: 2 }}>
                        Digital technology, artificial intelligence, and automation is the way the world is headed. And we believe stock trading and investments is no different. Our unique pricing plans are aimed at ensuring Tradetron is the most accessible algorithmic trading software in the market.We can also help you configure your custom strategy or provide other quant services at a nominal fee.
                    </Typography>
                    <Grid container item columns={12} gap={1} justifyContent={'center'} alignItems={'center'} textAlign={'center'} position={'relative'}>
                        {
                            Subscriptions.map((item, index) => (
                                <Grid item md={3} sm={12} xs={12} px={{ sm: 0, xs: 2 }} key={index} sx={{
                                    position: 'relative',
                                    cursor: 'default'
                                }}>
                                    <Card sx={{
                                        position: 'relative',
                                        transition: '200ms',
                                        overflow: 'hidden',
                                        '&::before': {
                                            content: "''",
                                            display: 'block',
                                            position: 'absolute',
                                            width: '100%',
                                            height: '300px',
                                            background: `url(${customCircle})`,
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            top: -120,
                                            right: -195,
                                            transform: 'rotate(20deg)',
                                        },
                                        ':hover': {
                                            boxShadow: '0px 0px 10px 5px rgba(255,118,118,1)',
                                            overflow: 'hidden',
                                        }
                                    }}>
                                        <CardContent>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                p: 1,
                                                mx: 'auto',
                                                mb: 2,
                                                color: '#fff',
                                                background: theme.palette.secondary.main,
                                                borderRadius: 100,
                                                width: 'fit-content'
                                            }}>
                                                <CurrencyRupeeIcon />
                                            </Box>
                                            <Divider />
                                            <Typography variant='h5' component={'h5'} textTransform={'capitalize'} py={1}>{item.Name}</Typography>
                                            <Typography variant='h4' component={'p'} pb={1}>
                                                {item.price}
                                            </Typography>
                                            <Divider />
                                            <Typography variant='h5' component={'p'} textTransform={'capitalize'} pt={1}>
                                                Capital Req.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                {/* Features End */}
            </Grid>
        </ThemeProvider>
    )
}

export default Features