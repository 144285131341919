import React, { useState } from 'react'

// MUI Components
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { blueGrey } from '@mui/material/colors'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import LoadingButton from '@mui/lab/LoadingButton'
import { createTheme, ThemeProvider } from '@mui/material'

// assets
import css from './SignUp.module.css'

const theme = createTheme({
    palette: {
        primary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        },
        secondary: {
            light: blueGrey[50],
            main: blueGrey[50],
            dark: blueGrey[50],
        }
    },
})

const FinalStep = ({ registerUser, handleChange, handlePrev, handleSubmit, isClicked }) => {
    const [confirmPassword, setConfirmPassword] = useState({ value: '' })
    return (
        <ThemeProvider theme={theme}>
            <React.Fragment>
                <legend>
                    <IconButton aria-label="upload picture" component="label" >
                        <img
                            width={150}
                            height={150}
                            src={registerUser.profile}
                            alt={registerUser.fullName}
                            loading="lazy"
                            style={{
                                maxWidth: 150,
                                maxHeight: 150,
                                borderRadius: 100,
                                position: 'absolute',
                                objectFit: 'cover',
                            }}
                        />
                    </IconButton>
                </legend>
                <TextField
                    required
                    className={css.container_input}
                    name='username'
                    id="username"
                    label="Username"
                    variant="outlined"
                    type={'text'}
                    value={registerUser.username}
                    error={(registerUser.username.length >= 1 && registerUser.username.length < 3)}
                    helperText={(registerUser.username.length >= 1 && registerUser.username.length < 3) ? 'Proper Username is required' : ''}
                    onChange={event => handleChange(event)}
                />
                <TextField
                    required
                    className={css.container_input}
                    name='password'
                    id="password"
                    label="Password"
                    variant="outlined"
                    type={'password'}
                    value={registerUser.password}
                    error={registerUser.password.length > 0 && registerUser.password.length < 4}
                    helperText={registerUser.password.length > 0 && registerUser.password.length < 4 ? 'Password is too short!!' : ''}
                    onChange={event => handleChange(event)}
                />
                <TextField
                    required
                    className={css.container_input}
                    name='confirmPassword'
                    id="confirm-password"
                    label="Confirm Password"
                    variant="outlined"
                    type={'text'}
                    value={confirmPassword.value}
                    error={confirmPassword.value.length > 0 && confirmPassword.value !== registerUser.password}
                    helperText={confirmPassword.value.length > 0 && confirmPassword.value !== registerUser.password ? 'Password does not match!!' : ''}
                    onChange={event => setConfirmPassword(prev => ({ ...prev, value: event.target.value }))}
                />
                <Box sx={{ display: 'flex', flexDirection: 'row', py: 2, justifyContent: 'space-between' }}>
                    <Button
                        color="inherit"
                        onClick={handlePrev}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    {
                        isClicked ? (
                            <LoadingButton loading variant="outlined">
                                Submit
                            </LoadingButton>
                        ) : (
                            <Button
                                className={css.container_input}
                                variant='contained'
                                color='success'
                                sx={{ maxWidth: '100%' }}
                                onClick={handleSubmit}
                            >Submit</Button>
                        )
                    }
                </Box>
            </React.Fragment>
        </ThemeProvider>
    )
}

export default FinalStep